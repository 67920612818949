<!-- 特殊妇女新增/编辑弹窗-->
<template>
  <div>
    <el-dialog
      :z-index="10"
      :title="title"
      :visible.sync="visible"
      width="60%"
      @close="closeModal"
      :close-on-click-modal="false"
    >
      <!-- 左边竖线样式写法 -->
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
        "
      >
        <div style="display: flex; align-items: center; margin-bottom: 2%">
          <div style="border-left: 4px solid #556bff">&nbsp;</div>
          <div class="search-title">基本信息</div>
        </div>
        <a-button v-if="isEdit" type="primary" @click="choosePeople"
          >选择居民</a-button
        >
      </div>
      <!-- 基本信息 -->
      <div
        v-if="noChoice"
        style="
          background-color: #fff8de;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 30px;
        "
      >
        <div style="margin-top: 6px; margin-bottom: 6px">
          &nbsp;
          <a-icon type="info-circle" style="color: #e6a23c" />请选择人员
        </div>
      </div>
      <ax-form v-else ref="form" :formBuilder="formBuilder1">
        <div slot="file">
          <imagePreview ref="img1" :businessId="businessId"></imagePreview>
        </div>
      </ax-form>
      <!-- 家庭情况 -->
      <div style="display: flex; align-items: center; margin-bottom: 2%">
        <div style="border-left: 4px solid #556bff">&nbsp;</div>
        <div class="search-title">家庭情况</div>
      </div>
      <!-- 家庭情况 -->
      <ax-form ref="form1" :formBuilder="formBuilder2">
        <!-- 监护人姓名 -->
        <div slot="guardianName" style="display: flex">
          <a-input :value="guardianName" placeholder="请选择"></a-input>
          <ax-button @click="chooseGuardin" style="margin-left: 2%"
            >新增居民</ax-button
          >
        </div>
        <!-- 父亲姓名 -->
        <div slot="fatherName" style="display: flex">
          <a-input :value="fatherName" placeholder="请选择"></a-input>
          <ax-button @click="chooseFather" style="margin-left: 2%"
            >新增居民</ax-button
          >
        </div>
        <!-- 母亲姓名 -->
        <div slot="motherName" style="display: flex">
          <a-input :value="motherName" placeholder="请选择"></a-input>
          <ax-button @click="chooseMother" style="margin-left: 2%"
            >新增居民</ax-button
          >
        </div>
      </ax-form>
      <!-- 教育情况 -->
      <div style="display: flex; align-items: center; margin-bottom: 2%">
        <div style="border-left: 4px solid #556bff">&nbsp;</div>
        <div class="search-title">教育情况</div>
      </div>
      <!-- 教育情况 -->
      <ax-form ref="form2" :formBuilder="formBuilder3"> </ax-form>
      <!-- 生活情况 -->
      <div style="display: flex; align-items: center; margin-bottom: 2%">
        <div style="border-left: 4px solid #556bff">&nbsp;</div>
        <div class="search-title">生活情况</div>
      </div>
      <!-- 生活情况 -->
      <ax-form ref="form3" :formBuilder="formBuilder4" @change="povertyChange">
      </ax-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="onSubmit"
          :loading="saveType"
          style="background-color: #556bff"
          >保 存</el-button
        >
        <el-button @click="visible = false">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 选择居民弹窗 -->
    <choose-people
      ref="choosePeople"
      @receive="receivePeopleId"
      :selectedPeopleList="selectedPeopleList"
    ></choose-people>
    <!-- 选择监护人 -->
    <choose-guardian
      ref="chooseGuardin"
      @choose="receiveGuardianId"
    ></choose-guardian>
    <!-- 选择父亲 -->
    <choose-father ref="chooseFather" @choose="receiveFatherId"></choose-father>
    <!-- 选择母亲 -->
    <choose-mother ref="chooseMother" @choose="receiveMotherId"></choose-mother>
  </div>
</template>
<script>
const formList1 = [
  {
    label: "姓名",
    type: "",
    model: "residentName",
    options: { allowClear: true, placeholder: "请输入" },
    col: { span: 12 },
    rules: [{ required: false, message: "请输入姓名" }],
  },
  {
    label: "个人照片",
    type: "",
    model: "file",
    options: {},
    col: { span: 12 },
    rules: [{ required: false, message: "" }],
  },
  {
    label: "身份证号",
    type: "",
    model: "identityCard",
    options: { allowClear: true, maxLength: 18, placeholder: "请输入" },
    col: { span: 12 },
    rules: [
      {
        required: false,
        message: "请输入",
        pattern:
          /^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{4}$/ |
          /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$/,
      },
    ],
  },
  {
    label: "性别",
    type: "select",
    model: "gender",
    options: { disabled: true, placeholder: "请先填写身份证号" },
    col: { span: 12 },
    rules: [{ required: false, message: "请先填写身份证号" }],
  },
  {
    label: "年龄",
    type: "",
    model: "age",
    options: {
      disabled: true,
      placeholder: "请先填写身份证号",
    },
    col: { span: 12 },
    rules: [{ required: false }],
  },
  {
    label: "出生日期",
    type: "datePicker",
    model: "birthDate",
    options: {
      disabled: true,
      placeholder: "请先填写身份证号",
    },
    col: { span: 12 },
    rules: [{ required: false }],
  },
  {
    label: "联系电话",
    type: "",
    model: "phone",
    options: { placeholder: "请输入" },
    col: { span: 12 },
    rules: [{ required: false }],
  },
  {
    label: "现住区域",
    type: "",
    model: "currentArea",
    options: {},
    col: { span: 24 },
    rules: [{ required: false, message: "请输入结束时间" }],
  },
  {
    label: "详细地址",
    type: "",
    model: "detailedAddress",
    options: { placeholder: "请选择详细地址" },
    col: { span: 12 },
    rules: [{ required: false }],
  },
];
const formList2 = [
  {
    label: "家庭人口数",
    type: "inputNumber",
    model: "familySize",
    options: {placeholder:'请输入'},
    col: { span: 12 },
    rules: [{ required: false, message: "请输入结束时间" }],
  },
  {
    label: "监护人关系",
    type: "select",
    model: "guardianshipFee",
    options: {},
    col: { span: 12 },
    rules: [{ required: false, message: "请输入结束时间" }],
  },
  {
    label: "监护人姓名",
    type: "",
    model: "guardianName",
    options: {},
    col: { span: 12 },
    rules: [{ required: false, message: "请输入结束时间" }],
  },
  {
    label: "监护人联系电话",
    type: "",
    model: "guardianPhone",
    options: {placeholder:'请选择监护人/输入',maxLength:11},
    col: { span: 12 },
    rules: [
      {
        required: false,
        pattern: /^1[3-9]\d{9}$/,
        message: "请输入正确格式的联系电话",
      },
    ],
  },
  {
    label: "父亲姓名",
    type: "",
    model: "fatherName",
    options: {},
    col: { span: 12 },
    rules: [{ required: false, message: "请输入结束时间" }],
  },
  {
    label: "父亲联系电话",
    type: "",
    model: "fatherPhone",
    options: {
      placeholder:'请选择父亲/输入',maxLength:11
    },
    col: { span: 12 },
    rules: [
      {
        required: false,
        pattern: /^1[3-9]\d{9}$/,
        message: "请输入正确格式的联系电话",
      },
    ],
  },
  {
    label: "母亲姓名",
    type: "",
    model: "motherName",
    options: {},
    col: { span: 12 },
    rules: [{ required: false, message: "请输入结束时间" }],
  },
  {
    label: "母亲联系电话",
    type: "",
    model: "motherPhone",
    options: {
      placeholder:'请选择母亲/输入',maxLength:11
    },
    col: { span: 12 },
    rules: [
      {
        required: false,
        pattern: /^1[3-9]\d{9}$/,
        message: "请输入正确格式的联系电话",
      },
    ],
  },
];
const formList3 = [
  {
    label: "就读学校",
    type: "",
    model: "attendingSchool",
    options: { maxLength: 20,placeholder:'请输入' },
    col: { span: 12 },
    rules: [{ required: false }],
  },
  {
    label: "学校类型",
    type: "select",
    model: "schoolType",
    options: {},
    col: { span: 12 },
    rules: [{ required: false }],
  },
  {
    label: "年级类型",
    type: "select",
    model: "gradeType",
    options: {},
    col: { span: 12 },
    rules: [{ required: false }],
  },

  {
    label: "就读班级",
    type: "",
    model: "attendingClass",
    options: {placeholder:'请输入'},
    col: { span: 12 },
    rules: [{ required: false }],
  },
  {
    label: "学习成绩",
    type: "select",
    model: "academicRecord",
    options: {},
    col: { span: 12 },
    rules: [{ required: false }],
  },
  {
    label: "是否参加课外活动",
    type: "radio",
    model: "activityFlag",
    options: {
      options: [
        { label: "是", value: "0" },
        { label: "否", value: "1" },
      ],
    },
    col: { span: 12 },
    rules: [{ required: false }],
  },
];
const formList4 = [
  {
    label: "居住类型",
    type: "select",
    model: "liveType",
    options: {},
    col: { span: 12 },
    rules: [{ required: false }],
  },
  {
    label: "是否贫困",
    type: "radio",
    model: "povertyFlag",
    options: {
      options: [
        { label: "是", value: "0" },
        { label: "否", value: "1" },
      ],
    },
    col: { span: 12 },
    rules: [{ required: false }],
  },
  {
    label: "贫困等级",
    type: "select",
    model: "povertyLevel",
    options: {},
    col: { span: 12 },
    rules: [{ required: false }],
  },
];
import api from "./api";
import imagePreview from "./image-preview/imagePreview.vue";
import ChoosePeople from "./choosePeople.vue";
import ChooseGuardian from "./chooseGuardian.vue";
import ChooseFather from "./chooseFather.vue";
import ChooseMother from "./chooseMother.vue";
export default {
  components: {
    imagePreview,
    ChoosePeople,
    choosePeopleId: "",
    ChooseGuardian,
    ChooseFather,
    ChooseMother,
  },
  data() {
    return {
      options: [],
      visible: false,
      noChoice: true,
      isEdit: true,
      businessId: "",
      id: "",
      title: "",
      disabled: false,
      povertyFlag: "",
      fileIds: [], //图片数组
      saveType: false,
      formBuilder1: this.$common.initGridFormData(
        formList1,
        { layout: "horizontal", labelWidth: 150, disabled: true },
        { col: { span: 8 } }
      ),
      formBuilder2: this.$common.initGridFormData(
        formList2,
        { layout: "horizontal", labelWidth: 150 },
        { col: { span: 8 } }
      ),
      formBuilder3: this.$common.initGridFormData(
        formList3,
        { layout: "horizontal", labelWidth: 150 },
        { col: { span: 12 } }
      ),
      formBuilder4: this.$common.initGridFormData(
        formList4,
        { layout: "horizontal", labelWidth: 150 },
        { col: { span: 12 } }
      ),
      guardianName: "",
      fatherName: "",
      motherName: "",
      povertyFlag: "",
      baseInfoValue: {}, //基本信息
      familySituation: {}, //家庭情况
      educationSituation: {}, //教育情况
      livingConditions: {}, //生活情况
      selectedPeopleList: [],
    };
  },
  watch: {
    businessId(newval) {
      this.businessId = newval;
    },
  },
  computed: {},
  mounted() {},
  methods: {
    // 打开选择居民弹窗
    choosePeople() {
      this.$refs.choosePeople.openModal();
    },
    // 打开选择监护人弹窗
    chooseGuardin() {
      this.$refs.chooseGuardin.openModal();
    },
    // 打开选择父亲弹窗
    chooseFather() {
      this.$refs.chooseFather.openModal();
    },
    // 打开选择母亲弹窗
    chooseMother() {
      this.$refs.chooseMother.openModal();
    },
    // 接收子组件传过来的peopleId
    receivePeopleId(item) {
      if (item[0]) {
        this.noChoice = false;
        this.getPersonInfo(item[0]);
        this.businessId = item[0];
        this.$nextTick(() => {
          this.$refs.img1.getPicList(item[0]);
        });
      }
    },
    // 接收子组件传过来的监护人
    receiveGuardianId(item) {
      this.guardianName = item.residentName;
      this.$refs.form1.setFieldsValue({ guardianPhone: item.phone });
    },
    // 接收子组件传过来的父亲
    receiveFatherId(item) {
      this.fatherName = item.residentName;
      this.$refs.form1.setFieldsValue({ fatherPhone: item.phone });
    },
    // 接收子组件传过来的母亲
    receiveMotherId(item) {
      this.motherName = item.residentName;
      this.$refs.form1.setFieldsValue({ motherPhone: item.phone });
    },

    // 级联选择器改变的回调
    onChange(value) {
      console.log(value);
    },
    // 打开弹窗
    openModal(record = {}) {
      this.visible = true;
      this.noChoice = true;
      this.isEdit = true;
      this.saveType = false;
      this.guardianName = "";
      this.fatherName = "";
      this.motherName = "";
      this.povertyFlag = "";
      this.title = record.title;
      this.getDictionsary();
      this.selectedPeopleList = record.selectedPeopleList;
      console.log(this.selectedPeopleList, "勾选数据");
      this.$nextTick(() => {
        this.getDictionsary();
        this.$refs.form1.resetFields();
        this.$refs.form2.resetFields();
        this.$refs.form3.resetFields();
      });
      if (record.id) {
        this.id = record.id;
        this.noChoice = false;
        this.isEdit = false;
        this.businessId = record.residentId;
        this.getPersonInfo(record.residentId);
        this.getChildrenInfo(record.id);
        this.$nextTick(() => {
          this.$refs.img1.getPicList(record.residentId);
        });
      } else {
        this.id = "";
      }
    },
    // 关闭弹窗
    closeModal() {
      this.visible = false;
      this.$refs.form1.resetFields();
      this.$refs.form2.resetFields();
      this.$refs.form3.resetFields();
      this.$emit("refsh");
    },
    // 获取字典
    async getDictionsary() {
      // 特殊儿童监护人关系
      await api
        .dictData({ dicKind: "children_guardianship_fee" })
        .then((res) => {
          const options = res.data.map((res) => {
            return { label: res.dicDesc, value: res.dicCode };
          });
          this.$refs.form1.setFormItemProp("guardianshipFee", {
            options: { options, allowClear: true, placeholder: "请选择" },
          });
        });
      // 特殊儿童学校类型
      await api.dictData({ dicKind: "children_school_type" }).then((res) => {
        const options = res.data.map((res) => {
          return { label: res.dicDesc, value: res.dicCode };
        });
        this.$refs.form2.setFormItemProp("schoolType", {
          options: { options, allowClear: true, placeholder: "请选择" },
        });
      });
      // 特殊儿童年级类型
      await api.dictData({ dicKind: "children_grade_type" }).then((res) => {
        const options = res.data.map((res) => {
          return { label: res.dicDesc, value: res.dicCode };
        });
        this.$refs.form2.setFormItemProp("gradeType", {
          options: { options, allowClear: true, placeholder: "请选择" },
        });
      });
      // 特殊儿童学习成绩
      await api
        .dictData({ dicKind: "children_academic_record" })
        .then((res) => {
          const options = res.data.map((res) => {
            return { label: res.dicDesc, value: res.dicCode };
          });
          this.$refs.form2.setFormItemProp("academicRecord", {
            options: { options, allowClear: true, placeholder: "请选择" },
          });
        });
      // 特殊儿童居住类型
      await api.dictData({ dicKind: "children_live_type" }).then((res) => {
        const options = res.data.map((res) => {
          return { label: res.dicDesc, value: res.dicCode };
        });
        this.$refs.form3.setFormItemProp("liveType", {
          options: { options, allowClear: true, placeholder: "请选择" },
        });
      });
      // 特殊儿童贫困等级
      await api.dictData({ dicKind: "children_poverty_level" }).then((res) => {
        const options = res.data.map((res) => {
          return { label: res.dicDesc, value: res.dicCode };
        });
        if (!this.povertyFlag || this.povertyFlag === "0") {
          this.$refs.form3.setFormItemProp("povertyLevel", {
            options: {
              options,
              allowClear: true,
              placeholder: "请选择",
              hide: false,
            },
          });
        } else {
          this.$refs.form3.setFormItemProp("povertyLevel", {
            options: { allowClear: true, placeholder: "请选择", hide: true },
          });
        }
      });
    },
    // 身份证号改变的回调
    identityCardChange(value, key) {
      switch (key) {
        case "identityCard":
          this.calculateIDCardInfo(value);
          break;
      }
    },
    // 身份证号计算性别/年龄/出生日期
    calculateIDCardInfo(idCard) {
      let info = {
        gender: "",
        age: "",
        birthDate: "",
      };

      if (!idCard || (idCard.length !== 15 && idCard.length !== 18)) {
        return info;
      }

      let year =
        idCard.length === 15 ? "19" + idCard.substr(6, 2) : idCard.substr(6, 4);
      let month = idCard.substr(idCard.length === 15 ? 8 : 10, 2);
      let day = Number(idCard.substr(idCard.length === 15 ? 10 : 12, 2)) + 1;

      let genderCode =
        idCard.substr(idCard.length - 2, 1) % 2 === 0 ? "女" : "男";
      let birthday = new Date(year, parseInt(month) - 1, day); // 将月份减一
      let age = new Date().getFullYear() - birthday.getFullYear();
      info.gender = genderCode;
      info.age = age;
      info.birthDate = birthday.toISOString().slice(0, 10);
      this.$refs.form.setFieldsValue({ birthDate: info.birthDate });
      this.$refs.form.setFieldsValue({ gender: info.gender });
      this.$refs.form.setFieldsValue({ age: info.age });
      return info;
    },
    // 是否贫困改变的回调
    povertyChange(e, type) {
      console.log(e, type);
      if (type === "povertyFlag") {
        this.povertyFlag = e;
        this.getDictionsary();
        if (e === "0") {
          this.$refs.form3.setOptions(["povertyLevel"], "hide", false);
        }
        if (e === "1") {
          this.$refs.form3.setOptions(["povertyLevel"], "hide", true);
        }
      }
    },
    // 提交
    async onSubmit() {
      if (this.noChoice) {
        this.$message.warning("请先选择人员");
        return;
      }
      this.$refs.form.form.validateFields(async (err, value) => {
        if (err) return;
        console.log(value);
        this.baseInfoValue.residentId = this.businessId;
        if (this.$refs.form1) {
          this.$refs.form1.form.validateFields(async (err, value1) => {
            if (err) return;
            console.log(value1);
            this.familySituation = value1;
            this.familySituation.guardianName = this.guardianName;
            this.familySituation.fatherName = this.fatherName;
            this.familySituation.motherName = this.motherName;
            this.familySituation.guardianshipFee = value1.guardianshipFee || "";
            console.log(this.familySituation, "589");
            if (this.$refs.form2) {
              this.$refs.form2.form.validateFields(async (err, value2) => {
                if (err) return;
                console.log(value2);
                this.educationSituation = value2;
                this.educationSituation.schoolType = value2.schoolType || "";
                this.educationSituation.gradeType = value2.gradeType || "";
                this.educationSituation.academicRecord =
                  value2.academicRecord || "";
                if (this.$refs.form3) {
                  this.$refs.form3.form.validateFields(async (err, value3) => {
                    if (err) return;
                    this.livingConditions = value3;
                    this.livingConditions.liveType = value3.liveType || "";
                    this.livingConditions.povertyLevel =
                      value3.povertyLevel || "";
                    const totalValue = {
                      ...this.baseInfoValue,
                      ...this.familySituation,
                      ...this.educationSituation,
                      ...this.livingConditions,
                    };
                    console.log(totalValue, "375新增数据");
                    this.saveType = true;
                    if (this.id) {
                      totalValue.id = this.id;
                      const res = await api.updateChildren(totalValue);
                      console.log(res);
                      if (res.status === 200) {
                        this.$message.success("修改成功");
                        this.saveType = false;
                        this.closeModal();
                        this.$refs.form1.resetFields();
                        this.$refs.form2.resetFields();
                        this.$refs.form3.resetFields();
                      }
                    } else {
                      const res = await api.addChildren(totalValue);
                      console.log(res);
                      if (res.status === 200) {
                        this.$message.success("新增成功");
                        this.saveType = false;
                        this.closeModal();
                        this.$refs.form1.resetFields();
                        this.$refs.form2.resetFields();
                        this.$refs.form3.resetFields();
                      }
                    }
                  });
                }
              });
            }
          });
        }
      });
    },
    // 根据id获取详情
    async getPersonInfo(id) {
      const res = await api.getResidentArchivesById(id);
      this.$refs.form.setFieldsValue(res.data);
      this.$refs.form.setFieldsValue({
        detailedAddress: res.data.cellName
          ? `${res.data.cellName}/${res.data.buildingNum}/${res.data.unitNum}/${res.data.floorNum}/${res.data.accountNum}`
          : "",
      });

      this.calculateIDCardInfo(res.data.identityCard);
    },
    async getChildrenInfo(id) {
      const res = await api.getChildrenById(id);
      this.guardianName = res.data.guardianName;
      this.fatherName = res.data.fatherName;
      this.motherName = res.data.motherName;
      this.povertyFlag = res.data.povertyFlag;
      if (this.povertyFlag === "0") {
        this.$refs.form3.setOptions(["povertyLevel"], "hide", false);
        this.$refs.form3.setFieldsValue({
          povertyLevel: res.data.povertyLevel,
        });
      } else {
        this.$refs.form3.setOptions(["povertyLevel"], "hide", true);
      }
      this.$refs.form1.setFieldsValue(res.data);
      this.$refs.form2.setFieldsValue(res.data);
      this.$refs.form3.setFieldsValue(res.data);
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-dialog {
  border-radius: 20px !important;
}

.dialog-footer {
  display: flex;
  justify-content: end;
}

.search-title {
  // font-size: 0.072rem;
  font-weight: 800;
}
</style>